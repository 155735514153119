module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RockApp","short_name":"RockApp","description":"A guide to Sport Climbing in the Western Cape, South Africa","start_url":"/","display":"standalone","icon":"src/assets/rockapp-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ff2cce0bf75b015957874efbd607ef5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["*","/profile","/safety-at-the-crags","/using-this-guide","/montagu","/montagu/*","/montagu/legoland-sector","/montagu/legoland-sector/*","/montagu/bosch-kloof","/montagu/bosch-kloof/*","/montagu/kalkoennes","/montagu/kalkoennes/*","/montagu/the-fort","/montagu/the-fort/*","/montagu/the-sanctuary","/montagu/the-sanctuary/*","/montagu/keur-kloof","/montagu/keur-kloof/*","/montagu/cogmans-buttress","/montagu/cogmans-buttress/*","/montagu/riverside-crags","/montagu/riverside-crags/*","/montagu/bad-kloof","/montagu/bad-kloof/*","/montagu/donker-kloof","/montagu/donker-kloof/*","/montagu/lost-world","/montagu/lost-world/*","/montagu/guano-caves","/montagu/guano-caves/*","/montagu/the-farm-on-r62","/montagu/the-farm-on-r62/*","/montagu/oorlogs-kloof","/montagu/oorlogs-kloof/*","/montagu/simonskloof-mountain-retreat","/montagu/simonskloof-mountain-retreat/*","/montagu/gecko-rock-nature-reserve","/montagu/gecko-rock-nature-reserve/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
