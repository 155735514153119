import React from "react";
import { FirebaseContext, useAuth } from "./Firebase";

import 'normalize.css';
import '../styles/global.scss';

const Layout = ({ children }) => {
  const { user, firebase, loading } = useAuth();
  
  // handle expiry
  const handleCheckDates = () => {
    if (user.montaguUntil === "expired" || user.montaguUntil === undefined) {
      // do nothing
    } else {
      
      const now = new Date();
      const expiresOn = new Date(user.montaguUntil);

      if (now < expiresOn) {
        // still has access
      } else {
        firebase.expireAccess({
          codeId: user.montaguCode,
        }).then(() => {
          // access expired
          firebase.expireCode({
            codeId: user.montaguCode,
          }).then(() => {
            // code expired
          });
        }).catch(error => {
          console.log(error.message);
        });
      }
    }
  }

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <div className="wrapper">
        {children}
        {!!user && !!user.email &&
          <>
            {handleCheckDates()}
          </>
        }
      </div>
    </FirebaseContext.Provider>
  )
}

export default Layout;
