const firebaseConfig = {
  apiKey: "AIzaSyA8DAwYVsDZQQF36ZXkZIV8X8Qqrqxhf-w",
  authDomain: "western-cape-rock.firebaseapp.com",
  projectId: "western-cape-rock",
  storageBucket: "western-cape-rock.appspot.com",
  messagingSenderId: "897030184822",
  appId: "1:897030184822:web:9dd42f15e4dfac7ec96457",
  measurementId: "G-DDFH9XPY2M"
};

export default firebaseConfig;