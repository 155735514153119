import firebaseConfig from "./config";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

app.initializeApp(firebaseConfig);

class Firebase {
    constructor() {
        if (!firebaseInstance) {
            this.auth = app.auth();
            this.db = app.firestore();
            this.functions = app.functions();
            this.storage = app.storage();
        }
    }

    async registerGoogle() {
        var provider = new app.auth.GoogleAuthProvider();

        await this.auth.signInWithRedirect(provider)
        .then(() => {
            // Register/Log in successful
        }).catch((error) => {
            throw new Error(error.message);
        });
    }

    async registerGooglePopup() {
        var provider = new app.auth.GoogleAuthProvider();

        await this.auth.signInWithPopup(provider)
        .then(() => {
            // Register/Log in successful
        }).catch((error) => {
            throw new Error(error.message);
        });
    }

    async registerFacebook() {
        var provider = new app.auth.FacebookAuthProvider();

        await this.auth.signInWithRedirect(provider)
        .then(() => {
            // Register/Log in successful
        }).catch((error) => {
            throw new Error(error.message);
        })
    }

    async registerFacebookPopup() {
        var provider = new app.auth.FacebookAuthProvider();

        await this.auth.signInWithPopup(provider)
        .then(() => {
            // Register/Log in successful
        }).catch((error) => {
            throw new Error(error.message);
        })
    }

    async setUsername({ username }) {
        const checkUser = await this.db.collection('publicProfiles').doc(username).get();
        const createProfileCallable = this.functions.httpsCallable('createPublicProfile');

        if (checkUser.exists) {
            throw new Error("This username is already in use. Please try again.");
        } else {
            await this.auth.currentUser.updateProfile({
                username: username,
            })
            .then(() => {
                // Username successful
            })
            .catch((error) => {
                throw new Error(error.message);
            });
            return createProfileCallable({
                username
            });
        }
    }

    getUserProfile({ userId, onSnapshot }) {
        return this.db.collection('publicProfiles').where('userId', '==', userId).limit(1).onSnapshot(onSnapshot);
    }

    async logout() {
        await this.auth.signOut();
    }

    async updateDisplayName({ displayName }) {
        await this.auth.currentUser.updateProfile({
            displayName: displayName,
        })
        .then(() => {
            // Display name updated!
        })
        .catch((error) => {
            throw new Error(error.message);
        });
    }

    async redeemGuide({ userId, typedCode, guide }) {

        const codesRef = this.db.collection('redemptionCodes');
        const codeSnapshot = await codesRef.where('code', '==', typedCode).limit(1).get();

        if (codeSnapshot.empty) {
            throw new Error("The redemption code you entered is invalid.");
        } else {

            codeSnapshot.forEach(doc => {

                if (doc.data().used === false && doc.data().expiredOn === undefined) {

                    let now = new Date();
                    const activatedOn = now.toLocaleString("en-za");

                    codesRef.doc(doc.id).update({
                        used: true,
                        activatedOn: activatedOn,
                        userRef: userId
                    }).then(() => {

                        now.setDate(now.getDate() + doc.data().duration); // now date + x amount of days ("duration" from redemptionCodes)
                        const expiresOn = now.toISOString();

                        // montagu
                        if (guide === 'Montagu') {
                            const updateMontaguAccessCallable = this.functions.httpsCallable('updateMontaguAccess');
                            return updateMontaguAccessCallable({
                                montaguAccess: true,
                                montaguUntil: expiresOn,
                                montaguCode: doc.id
                            })
                            .then(() => {
                                // success
                            })
                            .catch((error) => {
                                throw new Error(error.message);
                            });
                        }

                    }).catch((error) => {
                        throw new Error(error.message);
                    });

                } else {
                    throw new Error("This redemption code has already been used.");
                }

            });
        }
    }

    async extendGuide({ userId, typedCode, guide, initialUntil, initialCode }) {

        const codesRef = this.db.collection('redemptionCodes');
        const codeSnapshot = await codesRef.where('code', '==', typedCode).limit(1).get();

        if (codeSnapshot.empty) {
            throw new Error("The redemption code you entered is invalid.");
        } else {

            codeSnapshot.forEach(doc => {

                if (doc.data().used === false && doc.data().expiredOn === undefined) {

                    // console.log(initialUntil);

                    let now = new Date();
                    let later = new Date(initialUntil);
                    const activatedOn = now.toLocaleString("en-za");

                    codesRef.doc(doc.id).update({
                        used: true,
                        activatedOn: activatedOn,
                        userRef: userId,
                        extends: initialCode
                    }).then(() => {

                        later.setDate(later.getDate() + doc.data().duration); // later date + x amount of days ("duration" from redemptionCodes)
                        const expiresOn = later.toISOString();

                        // montagu
                        if (guide === 'Montagu') {
                            const updateMontaguAccessCallable = this.functions.httpsCallable('updateMontaguAccess');
                            return updateMontaguAccessCallable({
                                montaguAccess: true,
                                montaguUntil: expiresOn,
                                montaguCode: doc.id
                            })
                            .then(() => {
                                // success
                            })
                            .catch((error) => {
                                throw new Error(error.message);
                            });
                        }

                    }).catch((error) => {
                        throw new Error(error.message);
                    });

                } else {
                    throw new Error("This redemption code has already been used.");
                }

            });
        }
    }

    async expireAccess({ codeId }) {
        
        const codeRef = this.db.collection('redemptionCodes').doc(codeId);
        const doc = await codeRef.get();

        if (!doc.exists) {
            // empty
        } else {
            if (doc.data().code.includes('mtg_') || doc.data().code.includes('MR01') || doc.data().code.includes('MR03') || doc.data().code.includes('MR12')) {
                const updateMontaguAccessCallable = this.functions.httpsCallable('updateMontaguAccess');
                return updateMontaguAccessCallable({
                    montaguAccess: false,
                    montaguUntil: 'expired',
                    montaguCode: 'empty',
                }).then(() => {
                    console.log('access expired');
                });
            }
        }
    }

    async expireCode({ codeId }) {

        const codeRef = this.db.collection('redemptionCodes').doc(codeId);
        const doc = await codeRef.get();

        if (!doc.exists) {
            // empty
        } else {
            let now = new Date();
            const expiredOn = now.toLocaleString("en-za");
            codeRef.update({
                expiredOn: expiredOn
            }).then(() => {
                window.location.reload();
            });
        }

    }

    async extendCode({ codeId, extendedCode }) {

        const codeRef = this.db.collection('redemptionCodes').doc(codeId);
        const doc = await codeRef.get();

        if (!doc.exists) {
            // empty
        } else {
            let now = new Date();
            const expiredOn = now.toLocaleString("en-za");
            codeRef.update({
                expiredOn: expiredOn,
                extendedBy: extendedCode
            });
        }

    }

    async addRedemptionCode({ code, duration, used, batch }) {

        const codesRef = this.db.collection('redemptionCodes');
        const codeSnapshot = await codesRef.where('code', '==', code).get();

        if (codeSnapshot.empty) {
            const createRedemptionCodeCallable = this.functions.httpsCallable('createRedemptionCode');
            return createRedemptionCodeCallable({
                code: code,
                duration: duration,
                used: used,
                batch: batch
            });
        }
    }

    async devExpireGuide() {
        const updateMontaguAccessCallable = this.functions.httpsCallable('updateMontaguAccess');
        return updateMontaguAccessCallable({
            montaguAccess: false,
            montaguUntil: 'expired',
            montaguCode: 'empty',
        });
    }

    // OLD THINGS
    async resetPassword({ email }) {
        await this.auth.sendPasswordResetEmail(email)
        .then(() => {
            // Password reset email sent!
        })
        .catch((error) => {
            if (error.code === "auth/user-not-found") {
                throw new Error("There is no user record corresponding to this email address.");
            } else {
                throw new Error(error.message);
            }
        });
    }
    async updateEmailAddress({ email }) {
        await this.auth.currentUser.updateEmail(email)
        .then(() => {
            return this.auth.currentUser.sendEmailVerification();
        })
        .catch((error) => {
            if (error.code === "auth/requires-recent-login") {
                throw new Error("This operation is sensitive and requires recent authentication. Log out, and log in again before retrying this request.");
            } else {
                throw new Error(error.message);
            }
        });
    }
    async verifyEmail() {
        return this.auth.currentUser.sendEmailVerification();
    }

}

let firebaseInstance;

function getFirebaseInstance() {
    if (!firebaseInstance) {
        firebaseInstance = new Firebase();
        return firebaseInstance;
    } else if (firebaseInstance) {
        return firebaseInstance;
    } else {
        return null;
    }
}

export default getFirebaseInstance;
